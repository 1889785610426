.footer--1P9E6 {
  color: hsla(0, 0%, 65%, 1);
  background: hsla(0, 0%, 15%, 1); }
  @media (max-width: 989px) {
    .footer__content-container--2q2pk {
      flex-direction: column;
      padding-bottom: 30px; } }
  .footer__links-block--32pCS {
    display: flex;
    flex-flow: row wrap; }
  .footer__links-container--3JkT9 {
    margin-top: 5px; }
    @media (min-width: 990px) {
      .footer__links-container--3JkT9 {
        margin-top: 0; } }
  .footer__services-container--1a4U4, .footer__links-names-container--3R40x {
    display: flex;
    flex-flow: row wrap;
    width: 100%; }
  .footer__service-name--2Nelw, .footer__link-name--3X6h5 {
    display: flex;
    flex-flow: row nowrap; }
  .footer__service-name--2Nelw {
    margin-top: 5px; }
    @media (min-width: 990px) {
      .footer__service-name--2Nelw {
        margin-top: 0; } }
  .footer__link-name--3X6h5 {
    margin-bottom: 5px; }
  .footer__phone-col--i1M12 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 25px; }
    @media (max-width: 989px) {
      .footer__phone-col--i1M12 {
        order: -1; } }
    @media (min-width: 990px) {
      .footer__phone-col--i1M12 {
        padding-bottom: 0; }
        .footer__phone-col--i1M12:not(.footer__phone-col--alone--2jVPG) {
          align-items: flex-end; } }
  @media (max-width: 989px) {
    .footer__service--16fRo:first-child {
      margin-top: 5px; }
    .footer__service--16fRo:not(.footer__service--last--1IbUs) {
      margin-bottom: 20px; } }
  .footer__toggler-title-container--3830z {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center; }
  .footer__toggler-visibility-toggler--2baCr {
    stroke: hsla(0, 0%, 65%, 1); }
    @media (min-width: 990px) {
      .footer__toggler-visibility-toggler--2baCr {
        display: none; } }
    .footer__toggler-visibility-toggler--active--2D1Zx {
      transform: rotate(180deg); }
  .footer__toggler-list--1WTZ_ {
    display: flex;
    flex-direction: column; }
    @media (max-width: 989px) {
      .footer__toggler-list--hidden--1RoED {
        display: none; } }
  @media (min-width: 990px) {
    .footer__links-list--yK4Y5 {
      flex-direction: row;
      flex-wrap: wrap; } }
  .footer__toggler-list-item--2Rhkh {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    white-space: nowrap; }
    @media (min-width: 990px) {
      .footer__toggler-list-item--2Rhkh {
        padding-right: 25px; } }
  @media (max-width: 989px) {
    .footer__toggler-link--15LzI {
      padding-bottom: 5px;
      padding-left: 5px; } }
  .footer__stores--jEIBK {
    display: flex;
    align-self: stretch; }
    @media (min-width: 990px) {
      .footer__stores--jEIBK {
        flex-direction: column;
        align-items: flex-end; } }
  @media (max-width: 989px) {
    .footer__store-link--2EWsg:first-child {
      margin-right: 15px; } }
  @media (min-width: 990px) {
    .footer__store-link--2EWsg:first-child {
      margin-bottom: 15px; } }
  .footer__last-row--15whv {
    display: flex;
    flex-direction: column;
    border-top: 1px solid hsla(0, 0%, 35%, 1); }
  .footer__copy_n_socnet--3YZis {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .footer__soc-net-container--1JawP {
    --svg-primary-color: hsla(0, 0%, 65%, 1);
    display: flex;
    align-items: center; }
  .footer__soc-net-link--13EfS {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px; }
